/* Base styling */
.navbar {
  /* background: linear-gradient(
    72.62363656667253deg,
    #242f4e 0%,
    rgba(112, 142, 228, 1) 100%
  );
  background-size: cover;
  background-repeat: no-repeat; */
  background-color: #242f4e;
  color: white;
  position: fixed; /* Make navbar fixed to top */
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000; /* High z-index to ensure navbar is on top */
}

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px; /* Fixed height for navbar */
  padding: 0 20px;
}

.logo {
  width: 8vw;
}

/* Style for navigation links */
.nav-menu {
  list-style: none;
  display: flex;
  margin: 0;
  transition: transform 0.3s ease; /* Smooth transition for menu */
}

.nav-item {
  margin-left: 20px;
}

.nav-link {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 16px;
}

.nav-link:hover {
  border-bottom: 1px dashed white;
}

/* Mobile menu icon */
.nav-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  width: 0px;
}

.cta-container {
  display: flex;
  gap: 20px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .logo {
    width: 20vw;
  }
  .nav-menu {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    display: none;
    flex-direction: column;
    background-color: #242f4e !important;
    border: none !important;
    border-bottom: 1px solid white !important;
    text-align: center;
    padding: 10px 0;
    transform: translateY(-100%); /* Start off-screen */
    width: 100%; /* Ensure it spans the full width */
  }

  .nav-item {
    margin: 10px 0;
  }

  .nav-menu.active {
    display: flex;
    transform: translateY(0%); /* Slide in effect */
  }

  .nav-icon {
    display: block;
    width: 0px;
  }

  /* Adjust content padding when nav is open */
  body {
    transition: padding-top 0.3s ease;
  }

  body.nav-open {
    padding-top: 220px; /* Adjust padding to accommodate expanded nav */
  }

  .started {
    display: none;
  }
}

.primary-cta {
  padding: 10px 24px;
  border-radius: 40px;
  cursor: pointer;
  background-color: #7499d6;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  border: 1px solid transparent;
}

.primary-cta:hover {
  border: 1px solid #fff;
}
.secondary-cta {
  background-color: transparent;
  outline: none;
  border: none;
  color: #fff;
}

/* styles.css */
.nav-link-gradient {
  background: linear-gradient(
    90deg,
    #ff7e5f,
    #feb47b
  ); /* Define your gradient colors here */
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  transition: background 0.3s ease-in-out;
  font-weight: bolder;
}
